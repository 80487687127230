<template>
  <div>
    <SiteHeaderComponent header="Artikelsuche" />
    <v-container
      class="elevation-2 white mt-4 mb-10 pt-8"
      d-flex
      justify-center
      flex-wrap
      style="max-width: 800px"
    >
      <v-responsive>
        <v-text-field
          class="mx-sm-4 mr-2"
          v-model="searchInput"
          solo
          label="Suche"
          clearable
          @keyup.enter="searchTrigger"
        ></v-text-field>
      </v-responsive>

      <v-btn
        class="mx-sm-4 py-6 px-8 primary"
        :loading="loading"
        @click="searchTrigger"
        >Suchen</v-btn
      >
      <TimeFilter class="col-12" />
    </v-container>

    <v-data-table
      :headers="headers"
      :items="results"
      :items-per-page="10"
      key="nr"
      class="elevation-1 mb-7 datatable-row-pointer"
      v-show="dataTableVisible"
      @click:row="openArticle"
    >
      <template v-slot:item.image_base64="{ item }">
        <img
          :src="item.image_base64"
          :alt="item.name"
          style="max-height: 70px"
          class="rounded pt-1"
        />
      </template>
      <template v-slot:no-data> Keine Daten gefunden. </template>
    </v-data-table>

    <div
      class="text-center mb-8"
      v-if="searchSuccessfull && results.length == 0"
    >
      <v-img
        class="mx-auto mt-4 mb-8"
        src="@/assets/svg/undraw_empty_xct9.svg"
        max-width="250px"
      ></v-img>
      Keine Daten gefunden. Bitte überprüfen Sie Ihre Eingabe.
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'

import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import TimeFilter from '@/components/statistics/TimeFilter.vue'

export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    searchInput: '',
    searchSuccessfull: false,
    results: [],
    cancelToken: undefined,
    loading: false,
    headers: [
      {
        text: 'Bild',
        align: 'start',
        sortable: false,
        value: 'image_base64'
      },
      { text: 'Artikelnummer', value: 'nr' },
      { text: 'Beschreibung', value: 'description' },
      {
        text: 'Anfangsbestand (Stk.)',
        value: 'openingStock',
        align: 'end'
      },
      {
        text: 'Einkäufe (Stk.)',
        value: 'purchases',
        align: 'end'
      },
      {
        text: 'Verkäufe (Stk.)',
        value: 'sales',
        align: 'end'
      },
      {
        text: 'Inventur (Stk.)',
        value: 'inventory',
        align: 'end'
      },
      {
        text: 'Materialverbrauch (Stk.)',
        value: 'materialsInput',
        align: 'end'
      },
      {
        text: 'Endbestand (Stk.)',
        value: 'finalStock',
        align: 'end'
      }
    ]
  }),
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    }),
    ...mapGetters(['searchAddress']),
    dataTableVisible() {
      if (this.results.length == 0) return false

      return true
    }
  },
  methods: {
    search(query) {
      if (query == undefined) return

      this.loading = true
      //Check if there are any previous pending requests
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel('Operation canceled due to new request.')
      }

      //Save the cancel token for the current request
      this.cancelToken = axios.CancelToken.source()

      axios
        .get(`/v1/statistics/distributors/${this.lfdnr}/articles/search`, {
          params: {
            q: query,
            from: this.filterFromDate,
            to: this.filterToDate
          },
          cancelToken: this.cancelToken.token
        })
        .then(response => {
          this.results = response.data
          this.searchSuccessfull = true
          this.loading = false
        })
        .catch(err => {
          console.log('error searching address: ' + err)
        })
    },
    searchTrigger() {
      this.$router.push({
        query: {
          ...this.$route.query,
          q: this.searchInput
        }
      })
      this.search(this.searchInput)
    },
    openArticle(item) {
      this.$router.push({
        name: 'statisticsArticle',
        params: { lfdnr: this.lfdnr, artNr: item.nr },
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        }
      })
    }
  },
  mounted() {
    if (this.$route.query['q'] != null) {
      this.searchInput = this.$route.query['q']

      this.search(this.searchInput)
    }
  },
  watch: {
    searchInput() {
      this.searchTrigger()
    },
    filterFromDate: function() {
      this.searchTrigger()
    },
    filterToDate: function() {
      this.searchTrigger()
    }
  }
}
</script>

<style lang="css" scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
